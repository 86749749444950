import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { SSRMouseEventWrapper, SSR_NAV_HOME_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteDataRef, useRouteUrl } from '@confluence/route-manager';
import { WIKI, HOME } from '@confluence/named-routes';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_HOME_SHORTCUT } from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { HomeOnboardingTakeoverV2HomeNudge } from '@confluence/experiment-home-onboarding-takeover-v2/entry-points/HomeOnboardingTakeoverV2HomeNudge';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { KeyboardShortcutWrapper } from '../KeyboardShortcutWrapper';
import { i18n } from '../globalNavigationTranslations';
import { HomeIconComponent } from '../GlobalNavigationIcons';

import type { GlobalItemProps } from './globalItemProps';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireHomeClickedAnalytics,
);

const isOnHomeSelector = {
	selector: (routeUrl: string | undefined) => {
		if (!routeUrl) {
			return false;
		}
		return routeUrl === HOME.toUrl() || routeUrl === WIKI.toUrl();
	},
};

export const YourWorkItem: FC<GlobalItemProps> = () => {
	//takes globalItemProps but is not customizable so doesn't use them
	const routeDataRef = useRouteDataRef();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const isSelected = useRouteUrl(isOnHomeSelector);

	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const homeUrl = HOME.toUrl();

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_HOME_BUTTON_METRIC}>
			<KeyboardShortcutWrapper
				href={homeUrl}
				keyboardShortcut={
					(isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')) ||
					(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
						? undefined
						: GO_HOME_SHORTCUT
				}
				isAppNavigationShortcut
				onShortcutTriggered={fireClickAnalytics}
			>
				<HomeOnboardingTakeoverV2HomeNudge position="right-start">
					<MenuLinkItem
						href={homeUrl}
						elemBefore={HomeIconComponent}
						isSelected={isSelected}
						onClick={fireClickAnalytics}
					>
						<FormattedMessage {...i18n.yourWork} />
					</MenuLinkItem>
				</HomeOnboardingTakeoverV2HomeNudge>
			</KeyboardShortcutWrapper>
		</SSRMouseEventWrapper>
	);
};
